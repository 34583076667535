import { Contrato, Curso, Turma } from "../../../../utils/knackKeys";
import { GET_ONE, METHODS } from "../../../../redux/actions/knack/types";
import { dbApiCall } from "../../../../redux/actions/api";
import { requiredErrorMessage } from "../../../../utils/errors";

const validate = ({
  fieldKey,
  getValues,
  setLoading = () => {},
  trigger,
  requiredValue,
  isMultiple,
}) => {
  const classField = Contrato.fields.TURMA;
  const currentClassField = Contrato.fields.TURMA_ATUAL;

  const classFieldToTrigger = () =>
    getValues(currentClassField)?.id ? currentClassField : classField;

  return {
    class: async (value) => {
      try {
        if (![classField, currentClassField].includes(fieldKey)) return true;
        const isClassButCurrentClassHasValue =
          fieldKey === classField && getValues(currentClassField)?.id;
        const isClearingCurrentClassAndClassHasValue =
          fieldKey === currentClassField &&
          !value &&
          !!getValues(classField)?.[0]?.id;

        if (isClassButCurrentClassHasValue) return true;

        if (isClearingCurrentClassAndClassHasValue) {
          trigger(classField);
          return true;
        }

        if (!value?.id && !value?.[0]?.id) return true;

        setLoading(true);
        const literacyLevel = getValues(
          Contrato.fields.HABILITACOES_LITERARIAS
        );
        if (!literacyLevel?.id) {
          setLoading(false);
          return true;
        }
        const classId = value?.length ? value[value.length - 1]?.id : value?.id;

        const selectedClass = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene: Turma.details.scene,
          view: Turma.details.view.details,
          id: classId,
        });

        const { data: classInfo } = selectedClass;
        if (classInfo[Turma.fields.FORMACAO_MODULAR] !== "Sim") {
          setLoading(false);
          return true;
        }

        const courseId = classInfo[Turma.fields.CURSO]?.[0]?.id;

        if (!courseId) {
          setLoading(false);
          return "A turma selecionada não tem o campo Curso preenchido. Para poder avançar, contacte este problema à Sede.";
        }

        const course = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene: Curso.details.scene,
          view: Curso.details.view,
          id: courseId,
        });

        const { data: courseInfo } = course;

        const allowedLiteracyLevels =
          courseInfo[Curso.fields.HABILITACOES_LITERARIAS_PERMITIDAS];

        if (allowedLiteracyLevels?.find((el) => el.id === literacyLevel.id)) {
          setLoading(false);
          return true;
        }
        setLoading(false);
        return "O formando não tem habilitações literárias adequadas para se inscrever nesta turma.";
      } catch (error) {
        console.log("error inside validate class", error);
        setLoading(false);
        return "Ocorreu um erro ao selecionar Turma, experimente selecionar novamente";
      }
    },
    literacyLevel: async (value) => {
      if (!value) return;
      if (fieldKey !== Contrato.fields.HABILITACOES_LITERARIAS) return true;
      const rightClassField = classFieldToTrigger();

      if (!getValues(Contrato.fields.HABILITACOES_LITERARIAS)?.id) {
        trigger(rightClassField);
        return true;
      }
      const currentClassValue = getValues(rightClassField);
      if (!currentClassValue) return true;
      trigger(rightClassField, { shouldFocus: true });
      return true;
    },
    required: (value) => {
      if (!requiredValue) return true;
      if (isMultiple) return !!value?.[0]?.id || requiredErrorMessage;
      return !!value?.id || requiredErrorMessage;
    },
  };
};

export default validate;
