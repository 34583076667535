import {
  CREATE_SESSION_CLASSES,
  EXPORT_AND_DELETE_SESSION_CLASSES,
  MARK_STUDENT_PRESENCES,
  REMOVE_STUDENT_PRESENCES,
  CLEAR_TABLE_STATE,
  OPEN_EVALUATIONS_CREATION_POPUP,
  PUSH_CLASSES_ONE_WEEK_FORWARD,
  OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP,
  OPEN_SEND_SMS_POP_UP,
  OPEN_SEND_EMAIL_POP_UP,
  PROCESSING_AUTOMATION,
  CHANGE_STUDENTS_CONTRACT_STATUS,
  CREATE_PRESENCES_MANUALLY,
  DOWNLOAD_QUALIFICA_DOCUMENTS,
} from "../constants/customActions";
import { IS_CUSTOM_POPUP_OPEN } from "../constants/popUps";
import { generateDebitsFile } from "./documents/contract";
import { store } from "../../redux/storeConfig";
import {
  createStudentPresencesWebhook,
  createPresencesInternShipsEvaluationWebhook,
  updateTeachersPermissionsWebhook,
  sendMassEmailAccessPuzzleWebhook,
  createSessionClassesWebhook,
  submitEvaluationsWebhook,
  sendCertificaAcoresWebhook,
  sendCertificateSigoWebhook,
  pushClassesOneWeekForwardWebhook,
  sendEmailWithAccessAndClassInformationWebhook,
  exportAndDeletePresencesWebhook,
  downloadQualificaDocumentsWebhook,
} from "../../utils/webhooks";
import {
  Aluno,
  Contrato,
  Aulas,
  Turma,
  Instalacoes,
  Presenca,
  Avaliacao,
} from "../../utils/knackKeys";
import { dbApiCall } from "./api";
import {
  GET_MULTIPLE_FILTERING_CONNECTED,
  GET_ONE,
  METHODS,
  UPDATE_ONE,
} from "./knack/types";
import callWebhook from "./api/callWebhook";
import { createDiploma } from "./documents/class";
import notify from "./helper";
import { ERROR, SUCCESS, WARNING } from "./notifications";

export const customAction =
  (customActionId, currentDetailId, object, gridApi, userId) =>
  async (dispatch) => {
    // gridApi.selectIndex(0)
    switch (customActionId) {
      case "createSessions": {
        const loadingMessage =
          "As aulas estão a ser criadas, poderá demorar alguns minutos até o processo estar terminado";
        const errorMessage =
          "As aulas não podem ser criadas, por favor adicione formador";
        const teacherExists = await checkIfTeacherExists(currentDetailId);
        if (!teacherExists)
          return notify({ dispatch, type: ERROR, message: errorMessage });
        notify({ dispatch, type: WARNING, message: loadingMessage });
        const result = await createSessionClasses(currentDetailId, object);
        dispatch({
          type: CREATE_SESSION_CLASSES,
          payload: { isTableUpdated: true },
        });
        if (!result)
          return notify({
            dispatch,
            type: ERROR,
            message: "Erro ao criar aulas",
          });
        return notify({
          dispatch,
          type: SUCCESS,
          message: "Aulas criadas!",
        });
      }
      case "markStudentPresence": {
        await markStudentPresence(gridApi);
        setTimeout(
          () =>
            dispatch({
              type: MARK_STUDENT_PRESENCES,
              payload: { isTableUpdated: true },
            }),
          2500
        );
        break;
      }
      case "removeStudentPresence": {
        await removeStudentPresence(gridApi);
        setTimeout(
          () =>
            dispatch({
              type: REMOVE_STUDENT_PRESENCES,
              payload: {
                isTableUpdated: true,
              },
            }),
          2500
        );
        break;
      }
      case "createEvaluations": {
        dispatch({
          type: OPEN_EVALUATIONS_CREATION_POPUP,
          payload: {
            type: "popup",
            componentName: "PopUpForEvaluations",
          },
        });
        dispatch({
          type: IS_CUSTOM_POPUP_OPEN,
          payload: true,
        });
        break;
      }
      case "pushClassesOneWeekForward": {
        const loadingMessage =
          "A alteração das aulas está a ser processado, será notificado(a) assim que o processo terminar";
        notify({ dispatch, type: WARNING, message: loadingMessage });
        await pushClassesOneWeekForward(gridApi);
        dispatch({
          type: PUSH_CLASSES_ONE_WEEK_FORWARD,
          payload: true,
        });
        break;
      }
      case "associateCareerManagers": {
        dispatch({
          type: OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP,
          payload: {
            type: "popup",
            componentName: "PopUpForMassAddCareerManager",
            webhook: "associateCareerManagers",
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "associateCareerManagerProcuraAtiva": {
        dispatch({
          type: OPEN_ASSOCIATE_CAREER_MANAGERS_POPUP,
          payload: {
            type: "popup",
            componentName: "PopUpForMassAddCareerManager",
            webhook: "associateCareerManagerProcuraAtiva",
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "changeStudentsContractStatus": {
        dispatch({
          type: CHANGE_STUDENTS_CONTRACT_STATUS,
          payload: {
            type: "popup",
            componentName: "PopUpForMassChangingContractStatus",
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "downloadQualificaDocuments": {
        const loadingMessage = "A gerar ficheiro... A ação demora uns minutos.";
        notify({ dispatch, type: WARNING, message: loadingMessage });
        downloadQualificaDocuments(gridApi);
        dispatch({
          type: DOWNLOAD_QUALIFICA_DOCUMENTS,
          payload: true,
        });
        break;
      }
      case "sendMassSMS": {
        dispatch({
          type: OPEN_SEND_SMS_POP_UP,
          payload: {
            type: "popup",
            componentName: "PopUpForMassSMS",
            object,
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "sendMassEmail": {
        dispatch({
          type: OPEN_SEND_EMAIL_POP_UP,
          payload: {
            type: "popup",
            componentName: "PopUpForMassEmail",
            object,
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "generateDebitsFile": {
        await generateDebitsFile({ gridApi, dispatch });
        break;
      }
      case "createDiplomas": {
        dispatch({
          type: PROCESSING_AUTOMATION,
          payload: {
            processingStatus: "processing",
          },
        });
        const selectedNodes = gridApi.getSelectedNodes();
        const errors = await Promise.all(
          selectedNodes.map(
            async (node) =>
              await store.dispatch(
                createDiploma(currentDetailId, node?.data?.id)
              )
          )
        );
        const failedContracts = errors
          ?.filter((el) => !!el?.error)
          .map((el) => el?.error);
        if (failedContracts?.length) {
          console.log("errors in createDiplomas", errors);
          notify({
            dispatch,
            type: ERROR,
            message: `Ocorreu um erro ao gerar diploma para os seguintes contratos:<br/><b>${failedContracts.join(
              "<br/>"
            )}</b>.<br/><br/>Tente de novo ou fale com o suporte 👀`,
            options: { persist: true },
          });
        } else {
          notify({
            dispatch,
            type: SUCCESS,
            message:
              "Diplomas criados, associados ao contrato e enviados aos alunos com sucesso",
          });
        }
        dispatch({
          type: PROCESSING_AUTOMATION,
          payload: {
            processingStatus: "complete",
          },
        });
        break;
      }
      case "emailWithAccessAndClassInformation": {
        const loadingMessage = "O envio dos e-mails está a ser processado...";
        notify({ dispatch, type: SUCCESS, message: loadingMessage });
        await sendEmailWithAccessAndClassInformation(gridApi, currentDetailId);
        break;
      }
      case "emailWithAccessInformation": {
        const loadingMessage = "O envio dos e-mails está a ser processado...";
        notify({ dispatch, type: SUCCESS, message: loadingMessage });
        await sendEmailWithAccessInformation(gridApi, currentDetailId);
        break;
      }
      case "sendCertificateSigo": {
        const loadingMessage = "O envio dos e-mails está a ser processado...";
        notify({ dispatch, type: SUCCESS, message: loadingMessage });
        await sendCertificateSigo({ gridApi, currentDetailId, dispatch });
        break;
      }
      case "sendCertificaAcores": {
        const loadingMessage = "O envio dos e-mails está a ser processado...";
        notify({ dispatch, type: SUCCESS, message: loadingMessage });
        await sendCertificaAcores(gridApi);
        break;
      }
      case "createStudentPresences": {
        const loadingMessage =
          "As presenças estão a ser criadas, poderá demorar alguns minutos até o processo estar terminado";
        dispatch({
          type: CREATE_SESSION_CLASSES,
          payload: {
            isTableUpdated: true,
          },
        });
        notify({ dispatch, type: WARNING, message: loadingMessage });
        const result = await createStudentPresences(
          currentDetailId,
          object,
          userId
        );
        if (!result)
          notify({
            dispatch,
            type: WARNING,
            message:
              "Ocorreu um erro ao criar presenças. Verifique se a ação terminou e se faltar alguma coisa contacte o suporte.",
          });
        break;
      }
      case "exportAndDeletePresences": {
        const loadingMessage = "Exportando e apagando presenças...";
        notify({ dispatch, type: WARNING, message: loadingMessage });

        try {
          const response = await callWebhook({
            webhook: exportAndDeletePresencesWebhook,
            body: { id: currentDetailId },
          });
          dispatch({
            type: EXPORT_AND_DELETE_SESSION_CLASSES,
            payload: {
              isTableUpdated: true,
            },
          });
          notify({ dispatch, type: SUCCESS, message: response?.data });
        } catch (error) {
          const type = error?.response?.status === 400 ? "error" : WARNING;
          notify({ dispatch, type, message: error?.response?.data });
        }
        break;
      }
      case "createPresencesInternShipsEvaluation": {
        createPresencesInternShipsEvaluation(gridApi, currentDetailId);
        const loadingMessage =
          "As presenças, estágios e avaliações estão a ser criadas, poderá demorar alguns minutos até o processo estar terminado";
        notify({ dispatch, type: WARNING, message: loadingMessage });
        break;
      }
      case "updateTeachersPermissions": {
        updateTeachersPermissions(currentDetailId);
        const loadingMessage =
          "As permissões dos formadores estão a ser atualizadas, poderá demorar alguns minutos até o processo estar terminado";
        notify({ dispatch, type: WARNING, message: loadingMessage });
        break;
      }
      case "createPresencesManually": {
        const selectedClasses = gridApi.getSelectedNodes();
        const errorMessage = "Selecione apenas uma aula para criar presenças";
        if (selectedClasses.length > 1 || !selectedClasses.length)
          return notify({ dispatch, type: ERROR, message: errorMessage });
        const students = await getClassStudentsContracts(currentDetailId);
        const selectedClassFields = getSelectedClassFields(gridApi);
        dispatch({
          type: CREATE_PRESENCES_MANUALLY,
          payload: {
            type: "popup",
            componentName: "PopUpForCreatingPresences",
            students,
            selectedClassFields,
            classData: gridApi.getSelectedNodes()[0].data,
          },
        });
        dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
        break;
      }
      case "submitEvaluations": {
        await submitEvaluations(currentDetailId, userId);
        const loadingMessage =
          "Está a ser enviada uma notificação à Centros Talento de que TODAS as avaliações foram submetidas. Obrigado!";
        notify({ dispatch, type: WARNING, message: loadingMessage });
        break;
      }
      default:
        return null;
    }
  };

const checkIfTeacherExists = async (currentDetailId) => {
  try {
    const scene = "scene_44";
    const view = "view_54";

    const response = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene,
      view,
      id: currentDetailId,
    });
    let teacher = response?.data?.field_73;

    if (teacher?.length) return true;
    else return false;
  } catch (error) {
    console.log("error in checkIfTeacherExists", error);
    return false;
  }
};

const createSessionClasses = async (currentDetailId, object) => {
  try {
    const response = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_MULTIPLE_FILTERING_CONNECTED,
      scene: "scene_243",
      view: "view_334",
      id: currentDetailId,
      urlParams: {
        connectedField: Aulas.fields.TURMA,
      },
    });

    if (response.data?.records?.length > 0) {
      alert("Esta turma já tem aulas criadas");
    } else {
      callWebhook({
        webhook: createSessionClassesWebhook,
        body: {
          detailId: currentDetailId,
          object,
        },
      });
      return true;
    }
    return false;
  } catch (error) {
    console.log("error in createSessionClasses", error);
    return false;
  }
};

const createStudentPresences = async (currentDetailId, object, userId) => {
  try {
    const response = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_MULTIPLE_FILTERING_CONNECTED,
      scene: "scene_248",
      view: "view_341",
      id: currentDetailId,
      urlParams: {
        connectedField: "field_429",
      },
    });

    if (response.data?.records?.length > 0) {
      alert("Esta turma já tem presenças criadas");
    } else {
      const classData = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_ONE,
        scene: "scene_44",
        view: "view_54",
        id: currentDetailId,
      });

      const classSessions = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_MULTIPLE_FILTERING_CONNECTED,
        scene: "scene_243",
        view: "view_334",
        id: currentDetailId,
        urlParams: {
          connectedField: Aulas.fields.TURMA,
          params: { itemsPerPage: 50 },
        },
      });

      const classContracts = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_MULTIPLE_FILTERING_CONNECTED,
        scene: Contrato.list.scene,
        view: Contrato.list.view,
        id: currentDetailId,
        urlParams: {
          connectedField: Contrato.fields.TURMA,
          params: { itemsPerPage: 50 },
        },
      });

      await Promise.all(
        classContracts.data.records.map(async (contract) => {
          const body = {
            userId,
            detailId: currentDetailId,
            object,
            classData: classData.data,
            classSessions: classSessions.data,
            classContracts: contract,
          };
          await callWebhook({ webhook: createStudentPresencesWebhook, body });
        })
      );

      return true;
    }
    return false;
  } catch (error) {
    console.log("error in createStudentPresences", error);
    return false;
  }
};

const markStudentPresence = async (gridApi) => {
  const selectedNodes = gridApi.getSelectedNodes();
  await Promise.all(
    selectedNodes.map(async (node, index) => {
      try {
        await dbApiCall({
          action: METHODS.PUT.value,
          type: UPDATE_ONE,
          scene: Presenca.details.scene,
          view: Presenca.details.view,
          id: node.data.id,
          formData: { [Presenca.fields.PRESENTE]: "Sim" },
          forceRetry: true,
        });
      } catch (error) {
        console.log(
          `Não se marcou a presença para o aluno nº ${Number(index) + 1}`
        );
      }
    })
  );
};

const removeStudentPresence = async (gridApi) => {
  const selectedNodes = gridApi.getSelectedNodes();
  await Promise.all(
    selectedNodes.map(async (node, index) => {
      try {
        await dbApiCall({
          action: METHODS.PUT.value,
          type: UPDATE_ONE,
          scene: Presenca.details.scene,
          view: Presenca.details.view,
          id: node.data.id,
          formData: { [Presenca.fields.PRESENTE]: "Não" },
          forceRetry: true,
        });
      } catch (error) {
        console.log(
          `Não se removeu a presença para o aluno nº ${Number(index) + 1}`
        );
      }
    })
  );
};

const pushClassesOneWeekForward = async (gridApi) => {
  try {
    const selectedNodes = gridApi.getSelectedNodes();
    const data = selectedNodes.map((node) => node.data);

    await callWebhook({
      webhook: pushClassesOneWeekForwardWebhook,
      body: { data },
    });
  } catch (error) {
    console.log("error in pushClassesOneWeekForward", error);
  }
};

const downloadQualificaDocuments = (gridApi) => {
  const selectedNodes = gridApi.getSelectedNodes();
  const contracts = selectedNodes.map((node) => ({
    contractId: node?.data?.id,
  }));
  const classId = selectedNodes?.[0]?.data?.[Contrato.fields.TURMA][0]?.id;

  const url = `${downloadQualificaDocumentsWebhook}?contracts=${JSON.stringify(
    contracts
  )}&classId=${classId}`;
  window.open(url, "_blank").focus();
};

export const clearTableState = () => (dispatch) =>
  dispatch({ type: CLEAR_TABLE_STATE });

const sendEmailWithAccessAndClassInformation = async (gridApi) => {
  const selectedNodes = gridApi.getSelectedNodes();

  await Promise.all(
    selectedNodes.map(async (node) => {
      try {
        const contractId = node?.data?.id;
        const classId = node?.data?.[Contrato.fields.TURMA][0]?.id;
        const classData = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene: Turma.details.scene,
          view: Turma.details.view.details,
          id: classId,
        });

        const installationsId =
          classData?.data?.[Turma.fields.INSTALACOES][0]?.id;
        const installationsData = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene: Instalacoes.details.scene,
          view: Instalacoes.details.view.details,
          id: installationsId,
        });

        const studentId = node?.data?.[Contrato.fields.ALUNO][0]?.id;
        const studentData = await dbApiCall({
          action: METHODS.GET.value,
          type: GET_ONE,
          scene: Aluno.details.scene,
          view: Aluno.details.view.personalData,
          id: studentId,
        });

        const data = {
          contractId: contractId,
          classData: classData?.data,
          installationsData: installationsData?.data,
          studentData: studentData.data,
        };

        await callWebhook({
          webhook: sendEmailWithAccessAndClassInformationWebhook,
          body: { data },
        });
      } catch (error) {
        console.log("error in sendEmailWithAccessAndClassInformation");
      }
    })
  );
};
const sendEmailWithAccessInformation = async (gridApi) => {
  try {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedContracts = selectedNodes.map((node) => node.data);

    await callWebhook({
      webhook: sendMassEmailAccessPuzzleWebhook,
      body: { data: selectedContracts },
    });
  } catch (error) {
    console.log("error in sendEmailWithAccessInformation", error);
  }
};

const sendCertificateSigo = async ({ gridApi, dispatch }) => {
  const selectedNodes = gridApi.getSelectedNodes();

  try {
    const body = {
      data: selectedNodes.map(
        (node) => node?.data?.[Avaliacao.fields.CONTRATO]?.[0].id
      ),
    };
    await callWebhook({
      webhook: sendCertificateSigoWebhook,
      body,
    });
  } catch (error) {
    console.log(
      `Erro ao enviar o passaporte qualifica e certificado`,
      error?.response?.data || error.message
    );
    notify({
      dispatch,
      type: ERROR,
      message: error?.response?.data || error.message,
    });
  }
};

const sendCertificaAcores = async (gridApi) => {
  const selectedNodes = gridApi.getSelectedNodes();

  await Promise.all(
    selectedNodes.map(async (node, index) => {
      try {
        const contractId = node?.data?.id;
        const body = { data: contractId };

        await callWebhook({ webhook: sendCertificaAcoresWebhook, body });
      } catch (error) {
        console.log(
          `Erro ao enviar para o aluno nº ${Number(index) + 1}`,
          error
        );
      }
    })
  );
};

const createPresencesInternShipsEvaluation = async (
  gridApi,
  currentDetailId
) => {
  const selectedNodes = gridApi.getSelectedNodes();

  await Promise.all(
    selectedNodes.map(async (node, index) => {
      try {
        const body = {
          data: {
            contractId: node?.data?.id,
            studentId: node?.data?.[Contrato.fields.ALUNO][0]?.id,
            classId: currentDetailId,
          },
        };
        await callWebhook({
          webhook: createPresencesInternShipsEvaluationWebhook,
          body,
        });
      } catch (error) {
        console.log(
          `Erro ao criar presenças, estágio e avaliação para o aluno nº ${
            Number(index) + 1
          }`,
          error
        );
      }
    })
  );
};

const updateTeachersPermissions = async (currentDetailId) => {
  try {
    await callWebhook({
      webhook: updateTeachersPermissionsWebhook,
      body: { classId: currentDetailId },
    });
  } catch (error) {
    console.log("error in updateTeachersPermissions", error);
  }
};

const getClassStudentsContracts = async (currentDetailId) => {
  try {
    const classContracts = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_MULTIPLE_FILTERING_CONNECTED,
      scene: Contrato.list.scene,
      view: Contrato.list.view,
      id: currentDetailId,
      urlParams: {
        connectedField: Contrato.fields.TURMA,
      },
    });
    return classContracts.data.records.map(
      (contract) => contract[Contrato.fields.ALUNO][0]
    );
  } catch (error) {
    console.log("error in getClassStudentsContracts", error);
  }
};

const getSelectedClassFields = (gridApi) => {
  const { SESSOES, TURMA, SESSAO_NUM } = Aulas.fields;

  const {
    [SESSOES]: classSession,
    [TURMA]: className,
    [SESSAO_NUM]: classSessionNumber,
  } = gridApi.getSelectedNodes()[0].data;
  const fieldsWithValue = [
    { [SESSOES]: classSession[0].identifier, key: SESSOES },
    { [TURMA]: className[0].identifier, key: TURMA },
    { [SESSAO_NUM]: classSessionNumber, key: SESSAO_NUM },
  ];
  return fieldsWithValue;
};

const submitEvaluations = async (currentDetailId, userId) => {
  try {
    const body = {
      data: { teacherId: userId, classId: currentDetailId },
    };
    await callWebhook({ webhook: submitEvaluationsWebhook, body });
  } catch (error) {
    console.log("error in submitEvaluations", error);
  }
};
