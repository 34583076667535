import React from "react";
import { connect } from "react-redux";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import _ from "lodash";
import {
  disableFieldForUser,
  userCanSeeField,
} from "../../../utils/userPermissions";
import { renderFieldValue } from "../../../utils/renderFieldValue";
import useStyles from "./style";
import PasswordField from "../forms/PasswordField";
import UploadFile from "../forms/UploadFile";
import ReactQuill from "react-quill";
import PopUpRichEditor from "../customComponents/PopUps/PopUpRichEditor";
import { setRichPopUpOpen } from "../../../redux/actions/popUps";
import TalentoButton from "../TalentoButton";

const RenderDetailField = ({
  object,
  group,
  record,
  sceneEdit,
  viewEdit,
  auth,
  isRichPopUpOpen,
  setRichPopUpOpen,
  uploadToFolderField,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment key={group.id}>
      {group.fields.map((field) => {
        if (userCanSeeField(field.canView, auth.profile_keys)) {
          if (
            field.type !== "address" &&
            field.type !== "file" &&
            field.type !== "image" &&
            field.type !== "rich_text" &&
            field.type !== "password"
          ) {
            return (
              <TableRow key={field.key}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.titleCell}
                  style={{ width: "40%" }}
                >
                  {field.name}:
                </TableCell>
                <TableCell align="left">
                  {renderFieldValue({
                    field,
                    record,
                    layout: "detail",
                    profileKeys: auth.profile_keys,
                  })}
                </TableCell>
              </TableRow>
            );
            // To refactor later, this fields needs to go to the utils renderFieldValue function
          } else if (field.type === "address") {
            if (typeof _.pick(record, field.key)[field.key] !== "undefined") {
              return (
                <React.Fragment key={field.key}>
                  <TableRow key={`${[field.key]}-street`}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.titleCell}
                      style={{ width: "40%" }}
                    >
                      Morada:
                    </TableCell>
                    <TableCell align="left">
                      {_.pick(record, field.key)[field.key].street}
                    </TableCell>
                  </TableRow>
                  <TableRow key={`${[field.key]}-zip`}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.titleCell}
                      style={{ width: "40%" }}
                    >
                      Código Postal:
                    </TableCell>
                    <TableCell align="left">
                      {_.pick(record, field.key)[field.key].zip}
                    </TableCell>
                  </TableRow>
                  <TableRow key={`${[field.key]}-city`}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.titleCell}
                      style={{ width: "40%" }}
                    >
                      Localidade:
                    </TableCell>
                    <TableCell align="left">
                      {_.pick(record, field.key)[field.key].city}
                    </TableCell>
                  </TableRow>
                  <TableRow key={`${[field.key]}-country`}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.titleCell}
                      style={{ width: "40%" }}
                    >
                      País:
                    </TableCell>
                    <TableCell align="left">
                      {_.pick(record, field.key)[field.key].country}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            }
          } else if (field.type === "password") {
            return (
              <TableRow key={field.key}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.titleCell}
                  style={{ width: "40%" }}
                >
                  {field.name}:
                </TableCell>
                <TableCell align="left">
                  <PasswordField
                    field={field.key}
                    scene={sceneEdit}
                    view={viewEdit}
                    recordId={record.id}
                  />
                </TableCell>
              </TableRow>
            );
          } else if (field.type === "file") {
            const uploadToFolder = record?.[uploadToFolderField]
            return (
              <TableRow key={field.key}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.titleCell}
                  style={{ width: "40%" }}
                >
                  {field.name}:
                </TableCell>
                <TableCell align="left">
                  <UploadFile
                    url={
                      typeof _.pick(record, field.key)[field.key] !==
                      "undefined"
                        ? _.pick(record, field.key)[field.key]
                        : ""
                    }
                    field={field.key}
                    fieldConfig={field}
                    scene={sceneEdit}
                    view={viewEdit}
                    recordId={record.id}
                    uploadToFolder={uploadToFolder}
                    object={object}
                  ></UploadFile>
                </TableCell>
              </TableRow>
            );
          } else if (field.type === "rich_text") {
            return (
              <TableRow key={field.key}>
                <TableCell colSpan={2} style={{ widht: "100%" }}>
                  <div>
                    <Typography variant="inherit" className={classes.titleCell}>
                      {field.name}:
                    </Typography>
                  </div>

                  {_.pick(record, field.key)[field.key] &&
                  _.pick(record, field.key)[field.key] !== "undefined" ? (
                    <ReactQuill
                      theme="snow"
                      value={_.pick(record, field.key)[field.key]}
                      readOnly={true}
                      modules={{ toolbar: false }}
                      style={{
                        maxHeight: "300px",
                        overflow: "scroll",
                      }}
                    />
                  ) : null}
                  <PopUpRichEditor
                    isRichPopUpOpen={isRichPopUpOpen}
                    popUpTitle={field.name}
                    scene={sceneEdit}
                    view={viewEdit}
                    field={field}
                    recordId={record.id}
                    recordValues={
                      typeof _.pick(record, field.key)[field.key] !==
                      "undefined"
                        ? _.pick(record, field.key)[field.key]
                        : ""
                    }
                  />
                  <TalentoButton
                    variant="contained"
                    color="primary"
                    onClick={() => setRichPopUpOpen(true)}
                    style={{
                      marginLeft: "auto",
                      marginTop: 10,
                    }}
                    disabled={disableFieldForUser(
                      field.canEdit,
                      auth.profile_keys
                    )}
                  >
                    Editar
                  </TalentoButton>
                </TableCell>
              </TableRow>
            );
          } else return null;
        }
        return null;
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isRichPopUpOpen: state.richPopUp,
});

export default connect(mapStateToProps, { setRichPopUpOpen })(
  RenderDetailField
);
