import { ERROR, INFO } from "../redux/actions/notifications";

const isDev = process.env.NODE_ENV === "development";

/** PROFILES */
export const Profiles = {
  ALUNO: "profile_4",
  FORMADOR: "profile_5",
  ORIENTADOR_PEDAGOGICO: "profile_20",
  AGENTE: "profile_21",
  COLABORADOR_SEDE: "profile_24",
  ADMIN: "profile_27",
};

/** CONTRATO */
export const Contrato = {
  object: "object_6",
  create: {
    scene: "",
    view: "",
  },
  edit: {
    scene: "",
    view: "",
  },
  details: {
    scene: "scene_96",
    view: "view_132",
  },
  list: {
    scene: "scene_94",
    view: "view_131",
  },
  fields: {
    TEM_PERMISSAO: "field_432",
    NUMERO_DO_CONTRATO: "field_48",
    DATA_DO_CONTRATO: "field_49",
    ALUNO: "field_50",
    IBAN: "field_52",
    PAGAMENTO_TOTAL: "field_54",
    PAGAMENTO_INICIAL: "field_55",
    VALOR_PRESTACAO: "field_56",
    NUMERO_PRESTACOES: "field_57",
    DATA_INICIO_PAGAMENTO_PRESTACOES: "field_58",
    SITUACAO_FINANCEIRA: "field_63",
    CURSO: "field_103",
    ORIENTADOR_PEDAGOGICO: "field_269",
    NIF: "field_284",
    NUMERO_CARTAO_CIDADAO: "field_287",
    VALIDADE_CARTAO_CIDADAO: "field_288",
    NUMERO_PASSAPORTE: "field_289",
    VALIDADE_PASSAPORTE: "field_290",
    NUMERO_AUTORIZACAO_RESIDENCIA: "field_291",
    VALIDADE_AUTORIZACAO_RESIDENCIA: "field_292",
    HABILITACOES_LITERARIAS: "field_295",
    EMAIL_ALUNO: "field_298",
    DIPLOMA: "field_310",
    TURMA: "field_313",
    FICHA_ESTAGIO_CRIADA: "field_314",
    COLABORADOR_SEDE: "field_318",
    INICIO_TURMA_CURSO: "field_465",
    FIM_TURMA_CURSO: "field_466",
    IDADE: "field_467",
    NATURALIDADE: "field_468",
    NACIONALIDADE: "field_469",
    NATURALIDADE_PAIS: "field_470",
    SITUACAO_FINANCEIRA_GERAL: "field_511",
    OBSERVACOES: "field_516",
    ULTIMA_TURMA_COM_ESTAGIO_E_AVALIACAO_CRIADA: "field_520",
    CENTRO: "field_527",
    SEXO: "field_535",
    CRIADO_EM: "field_537",
    TURMA_ATUAL: "field_556",
    ATUALIZADO_EM: "field_565",
    PASSAPORTE_QUALIFICA: "field_578",
    MEDIA_INQUERITO_AVALIACAO: "field_579",
    MEDIA_FORMADOR: "field_580",
    MEDIA_FORMACAO: "field_581",
    MEDIA_ORIENTADOR_PEDAGOGICO: "field_582",
    MEDIA_ATENDIMENTO_INSTALACOES: "field_583",
    MEDIA_GESTOR_CARREIRA: "field_584",
    INQUERITO_SATISFACAO: "field_309",
    RECOMENDA_CURSO: "field_585",
    ACESSO_PUZZLE: "field_588",
    DATA_ENVIO_QUESTIONARIO: "field_589",
    DATA_RESPOSTA_QUESTIONARIO: "field_592",
    ATUALIZACAO_DATAS: "field_611",
    OUTRO_CURSO_INTERESSE: "field_615",
    OBSERVACOES_SUGESTOES_MELHORIAS: "field_616",
    NOME: "field_619",
    CARTAO_CIDADAO: "field_620",
    NIF_ENC_EDUCACAO: "field_621",
    ENDERECO: "field_622",
    TELEMOVEL: "field_623",
    EMAIL: "field_624",
    TIPO_PAGAMENTO: "field_625",
    MEIO_PAGAMENTO_PRESTACOES: "field_628",
    VALOR_NUMERARIO: "field_631",
    VALOR_CHEQUE: "field_632",
    VALOR_MULTIBANCO_IFTHENPAY: "field_633",
    VALOR_TRANSFERENCIA: "field_634",
    VALOR_DEBITO_DIRETO: "field_635",
    DATA_GERACAO_CONTRATO: "field_636",
    DIA_DEBITO_PRESTACOES: "field_637",
    COMPROVATIVO_IBAN: "field_638",
    DOCUMENTO_IDENTIFICACAO_FRENTE: "field_639",
    COMPROVATIVO_PAGAMENTO_INSCRICAO: "field_640",
    DECLARACAO_REQUISITOS: "field_641",
    NATURALIDADE_DISTRITO: "field_642",
    EDICAO: "field_643",
    DATA_DEBITO_DIRETO_PAGAMENTO_INICIAL: "field_644",
    LOCALIZACAO_FORMANDO: "field_645",
    VALIDACAO_FINANCEIRA: "field_646",
    PERMISSOES_ATUALIZADAS: "field_649",
    AGENTE: "field_652",
    ENTIDADE_EMPREGADORA: "field_658",
    AREAS_FORMACAO: "field_659",
    DESEMPREGADO_HA_QUANTO_TEMPO: "field_660",
    OBSERVACOES_ORIENTADOR_PEDAGOGICO: "field_661",
    DISPONIBILDADE_HORARIA_FORMANDO: "field_663",
    SEPA_INSCRICAO: "field_664",
    COM_RH_VALOR: "field_669",
    ACESSO_RH_VALOR: "field_670",
    DOCUMENTO_IDENTIFICACAO_VERSO: "field_673",
    COMPROVATIVO_PAGAMENTO_INSCRICAO_MAIS_QUE_UMA_MODALIDADE_PAGAMENTO:
      "field_675",
    CERTIFICADO_RH_VALOR: "field_681",
    CRM_ID: "field_809",
    TURMA_INICIO_AUTOMATICO: "field_865",
    FORMACAO_MODULAR: "field_877",
    FORMACAO_MODULAR_CONTRATO: "field_919",
  },
  fieldsOptions: {
    SITUACAO_FINANCEIRA: [
      { value: "A liquidar a formação", level: INFO },
      { value: "Pagamento a longo prazo", level: INFO },
      { value: "Mensalidade(s) em atraso", level: ERROR },
      { value: "Pré-contencioso ", level: ERROR },
      { value: "Em acordo", level: ERROR },
      { value: "Contencioso", level: ERROR },
      { value: "Pagamentos congelados", level: ERROR },
      { value: "Anulação", level: INFO },
      { value: "Devolução", level: INFO },
      { value: "Curso liquidado", level: INFO },
    ],
  },
};

/** AULAS */
export const Aulas = {
  object: "object_38",
  create: {
    scene: "",
    view: "",
  },
  edit: {
    scene: "",
    view: "",
  },
  details: {
    scene: "",
    view: "",
  },
  fields: {
    SESSOES: "field_400",
    CURSO: "field_406",
    MODULO: "field_402",
    TURMA: "field_407",
    SESSAO_NUM: "field_408",
  },
};

/** TURMA */
export const Turma = {
  object: "object_7",
  details: {
    scene: "scene_44",
    view: {
      details: "view_54",
      contracts: "view_270",
    },
  },
  create: {
    scene: "scene_45",
    view: "view_56",
  },
  edit: {
    scene: "scene_46",
    view: "view_57",
  },
  fields: {
    DATA_INICIO: "field_69",
    CURSO: "field_81",
    INSTALACOES: "field_101",
    DATA_FIM: "field_186",
    DIA_SEMANA: "field_571",
    HORA_INICIO_SESSOES: "field_586",
    HORA_TERMINO_SESSOES: "field_684",
    FORMACAO_MODULAR: "field_856",
    FORMACAO_MODULAR_TURMA: "field_896",
  },
};

/** CURSO */
export const Curso = {
  object: "object_9",
  details: {
    scene: "scene_39",
    view: "view_47",
  },
  fields: {
    NOME: "field_75",
    CODIGO_INTERNO: "field_76",
    DESCRICAO_CURSO: "field_77",
    BROCHURA_CURSO: "field_79",
    FORMADOR: "field_80",
    CENTROS: "field_104",
    AREAS_FORMACAO: "field_107",
    MODALIDADE_FORMACAO: "field_378",
    FORMA_ORGANIZACAO_FORMACAO: "field_379",
    DOSSIER_TECNICO: "field_380",
    CARGA_HORARIA: "field_393",
    DESTINATARIOS: "field_404",
    NOME_CURTO: "field_477",
    TOTAL_MODULOS: "field_603",
    CATEGORIA_MOODLE: "field_730",
    SAIDAS_PROFISSIONAIS: "field_843",
    HABILITACOES_LITERARIAS_PERMITIDAS: "field_857",
  },
};

/** MODULOS */
export const Modulos = {
  object: "object_36",
  details: {
    scene: "",
    view: "",
  },
  fields: {
    CURSO: "field_391",
  },
};

/** INSTALACOES */
export const Instalacoes = {
  object: "object_8",
  details: {
    scene: "scene_35",
    view: {
      details: "view_42",
      people: "view_81",
    },
  },
  create: {
    scene: "scene_37",
    view: "view_45",
  },
  edit: {
    scene: "scene_36",
    view: "view_43",
  },
  createPerson: {
    scene: "scene_65",
    view: "view_84",
  },
  editPerson: {
    scene: "scene_66",
    view: "view_86",
  },
  fields: {},
};

/** ALUNO */
export const Aluno = {
  object: "object_4",
  details: {
    scene: "scene_29",
    view: {
      personalData: "view_31",
      actions: "view_38",
      contracts: "view_68",
    },
  },
  fields: {
    NIF: "field_17",
    NAME: "field_12",
    MORADA: "field_30",
    TEM_FORMACAO_MODULAR: "field_956",
    TEM_SO_FORMACAO_MODULAR: "field_962",
  },
};

/** ESTÁGIO */
export const Estagio = {
  object: "object_18",
  details: {
    scene: "",
    view: "",
  },
  fields: {
    PARCEIRO_EMPRESA: "field_168",
    CONTRATO: "field_170",
    DURACAO: "field_175",
    DATA_INICIO: "field_176",
    NOME_ALUNO: "field_371",
    NOTA_ESTAGIO: "field_514",
    SITUACAO_FINANCEIRA: "field_476",
  },
};

/** PRESENÇA */
export const Presenca = {
  object: "object_39",
  details: {
    scene: "scene_250",
    view: "view_342",
  },
  fields: {
    PRESENTE: "field_430",
  },
};

/** AVALIAÇÃO */
export const Avaliacao = {
  object: "object_41",
  details: {
    scene: "scene_277",
    view: "view_376",
  },
  fields: {
    TURMA: "field_446",
    ALUNO: "field_447",
    NOTA_FINAL: "field_454",
    CONTRATO: "field_500",
  },
};

/** PARCEIRO */
export const Parceiro = {
  object: "object_13",
  details: {
    scene: "",
    view: "",
  },
  fields: {
    DESIGNACAO_EMPRESA: "field_117",
    MORADA: "field_122",
    NIF: "field_118",
    CURSOS: "field_131",
    DURACAO_ESTAGIO: "field_132",
    DESCONTO_PARCERIA: "field_519",
    REPRESENTANTE_EMPRESA: "field_529",
  },
};

/** TEMPLATES */
export const Templates = {
  object: "object_44",
  list: {
    scene: "scene_302",
    view: "view_406",
  },
  fields: {
    NOME_TEMPLATE: "field_521",
    ID: "field_522",
    ASSUNTO: "field_524",
    TIPO: "field_525",
    ID_ZEPTOMAIL: "field_825",
    ACTIVE: "field_827",
    TEXT: "field_869",
    TEM_INFORMACAO_DINAMICA: "field_870",
    VARIAVEIS_DINAMICAS: "field_871",
    ENVIAR_DESDE: "field_875",
  },
};

/** CONFIG CAMPOS */
export const ConfigCampos = {
  object: "object_32",
  details: {
    scene: "",
    view: "",
  },
  create: {
    scene: "",
    view: "",
  },
  edit: {
    scene: "",
    view: "",
  },
  fields: {
    FIELD_ID: "field_330",
    POSITION_IN_DETAIL: "field_332",
    GROUP: "field_333",
    NOME_CAMPO: "field_338",
    ENTIDADE_FOR_FILTER: "field_344",
    CONNECTION_FIELD_IDENTIFIER: "field_345",
    TYPE: "field_346",
    CONNECTED_OBJECT: "field_347",
    SHOW_IN_TABLE: "field_348",
    OBJECT: "field_349",
    POSITION: "field_350",
    SHOW_IN_DETAIL: "field_370",
    POSITION_IN_HEADER: "field_458",
    CAN_EDIT: "field_486",
    CAN_VIEW: "field_526",
    CAN_CLICK_CONNECTED: "field_530",
    TYPE_IDENTIFIER: "field_531",
    PARENT_FILTER_IN_FORM: "field_690",
    PARENT_FILTER_IN_ENTITY: "field_691",
    FORCE_HIDE_EDIT: "field_852",
    FORCE_HIDE_CREATE: "field_853",
    LINE_IN_HEADER: "field_946",
  },
};

/** CONFIG GRUPOS */
export const ConfigGrupos = {
  object: "object_31",
  details: {
    scene: "",
    view: "",
  },
  create: {
    scene: "",
    view: "",
  },
  edit: {
    scene: "",
    view: "",
  },
  fields: {
    POSITION: "field_336",
    DISPLAY: "field_944",
  },
};

/** FORMAÇÃO MODULAR TURMA */
export const FormacaoModularTurma = {
  object: "object_51",
  details: {
    scene: "scene_377",
    view: isDev ? "view_486" : "view_489",
  },
  edit: {
    scene: "scene_376",
    view: isDev ? "view_485" : "view_488",
  },
  fields: {
    TURMA: "field_881",
  },
};

/** FORMAÇÃO MODULAR CONTRATO */
export const FormacaoModularContrato = {
  object: "object_52",
  details: {
    scene: "scene_381",
    view: isDev ? "view_490" : "view_493",
  },
  edit: {
    scene: "scene_380",
    view: isDev ? "view_489" : "view_492",
  },
  list: {
    scene: "scene_378",
    view: isDev ? "view_488" : "view_491",
  },
  fields: {
    CONTRATO: "field_897",
    CUSTOM_FOLDER: "field_936",
    ALUNO: "field_957"
  },
};
