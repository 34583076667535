import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { editRecord } from "../../../../redux/actions/api/dataApiCalls";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import { v4 as uuidv4 } from "uuid";
import { disableFieldForUser } from "../../../../utils/userPermissions";
import { knackRoles } from "../../../../utils/KnackAndFrontEndRel";
import { updateProfilePicture } from "../../../../redux/actions/auth";
import { backendUrl } from "../../../../utils/config";
import TalentoButton from "../../TalentoButton";
import { apiCall } from "../../../../redux/actions/api";
import { METHODS } from "../../../../redux/actions/knack/types";
import { Avatar } from "@mui/material";

const signedUrl = `${backendUrl}/knack/signUrl`;

const UploadFile = (props) => {
  let {
    field,
    fieldConfig,
    url,
    uploadToFolder = "",
    recordId,
    scene,
    view,
    auth,
  } = props;
  const { profile_keys } = props.auth;
  const [state, setState] = useState({
    isUploading: false,
    isUploaded: false,
    error: false,
    fieldFile: field,
    fileUrl: url,
    isLoading: false,
  });

  if (props.value) {
    url = props.value.url;
    field = props.value.field_key;
  }

  useEffect(() => {
    (async () => await getFileType(url))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFile = async (event) => {
    event.persist();

    setState({ ...state, isUploaded: false, isUploading: true });
    const file = event.target.files[0];
    const extension = file.name.split(".").pop();
    const fileName = `${
      uploadToFolder && uploadToFolder + "/"
    }${uuidv4()}.${extension}`;

    try {
      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrl,
        data: { type: "put", key: fileName },
        token: auth.token,
        shouldRetry: false,
      });
      await apiCall({
        action: METHODS.PUT.value,
        url: response.data.signUrl,
        data: event.target.files[0],
        shouldRetry: false,
      });

      const fileUrl = fileName;
      await props.editRecord({ [field]: fileUrl }, recordId, scene, view, auth);
      setState({ ...state, isUploaded: true, fileUrl });
      const isProfilePicture = recordId === auth.profileId;
      const isProfilePictureField = knackRoles.find(
        (role) => role.userPictureField === field
      );
      if (isProfilePicture && isProfilePictureField)
        await props.updateProfilePicture(fileUrl);
    } catch (err) {
      console.log("error while uploadFile", err);
      setState({ ...state, error: true });
    }
  };

  const getFileType = async (url) => {
    try {
      if (!url) return null;
      setState({ ...state, isLoading: true });

      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrl,
        data: { type: "get", key: url },
        token: auth.token,
        shouldRetry: false,
      });

      const {
        data: { fileName, signUrl },
      } = response;

      const getFileExtension = (fileExtension) => {
        switch (fileExtension) {
          case "jpg":
          case "png":
          case "jpeg":
            return "image";
          default:
            return "file";
        }
      };

      const fileExtension = fileName.split(".")[1];
      const fileType = getFileExtension(fileExtension);

      setState({ ...state, fileUrl: signUrl, fileType, isLoading: false });
    } catch (error) {
      console.log("error while fetching file type ", error);
      setState({ ...state, isLoading: false });
    }
  };

  const openFile = async () => {
    try {
      const response = await apiCall({
        action: METHODS.POST.value,
        url: signedUrl,
        data: { type: "get", key: url },
        token: auth.token,
        shouldRetry: false,
      });
      window.open(`${response.data.signUrl}`);
    } catch (error) {
      console.log("error in openFile", error);
    }
  };

  const File = () => {
    if (state.fileUrl)
      return (
        <TalentoButton
          onClick={openFile}
          color="primary"
          variant="contained"
          size="small"
        >
          Ver Dc.
        </TalentoButton>
      );
    return (
      <TalentoButton disabled={true} variant="contained" size="small">
        Sem Fic.
      </TalentoButton>
    );
  };

  const Image = ({ field, fieldConfig }) => {
    if (!state.fileUrl) return null;
    return (
      <Avatar
        slotProps={{ img: { loading: "lazy", id: field } }}
        onClick={openFile}
        src={state.fileUrl}
      >
        {state.isLoading ? fieldConfig?.name?.[0] : null}
      </Avatar>
    );
  };

  const isFileUploaded = disableFieldForUser(
    fieldConfig?.canEdit || field?.canEdit,
    profile_keys
  )
    ? null
    : !state.isUploading && !state.isUploaded;

  const UploadedFile = () => (
    <TalentoButton
      variant="contained"
      component="label"
      color="primary"
      disabled={state && state.isUploading}
      style={{ marginLeft: 10 }}
      size="small"
    >
      <input type="file" name="picture" hidden onChange={uploadFile} />
      <CloudUploadIcon color="inherit" fontSize="small" />
    </TalentoButton>
  );

  return (
    <Grid style={{ display: "flex", alignItems: "center" }}>
      {state.fileType === "file" && <File />}
      {state.fileType === "image" && (
        <Image field={field} fieldConfig={fieldConfig} />
      )}
      {isFileUploaded ? <UploadedFile /> : null}
      {state.isUploading ? (
        <CircularProgress color="primary" size={30} style={{ marginLeft: 5 }} />
      ) : null}
      {state.isUploaded ? (
        <CheckIcon
          color="primary"
          fontSize="large"
          style={{ marginLeft: 10 }}
        />
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  formSubmission: state.formSubmission,
  auth: state.auth,
});

export default connect(mapStateToProps, { editRecord, updateProfilePicture })(
  UploadFile
);
