import React from "react";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import DescriptionIcon from "@material-ui/icons/Description";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import BusinessIcon from "@material-ui/icons/Business";
// import HealingIcon from "@material-ui/icons/Healing";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

export const KnackAndFrontEndRelMainMenu = [
  {
    icon: <LocalLibraryIcon />,
    text: "Alunos",
    url: "/alunos",
    object: "object_4",
    scene: "scene_27",
    view: "view_29",
    withLink: true,
    profilesWithAccess: ["profile_27"],
  },
  {
    icon: <DescriptionIcon />,
    text: "Contratos",
    url: "/contratos",
    object: "object_6",
    scene: "scene_94",
    view: "view_131",
    withLink: true,
    profilesWithAccess: ["profile_27", "profile_21", "profile_20"],
  },
  {
    icon: <CastForEducationIcon />,
    text: "Turmas",
    url: "/turmas",
    object: "object_7",
    scene: "scene_43",
    view: "view_53",
    withLink: true,
    profilesWithAccess: ["profile_27", "profile_5", "profile_20", "profile_21"],
  },
  {
    icon: <SupervisorAccountIcon />,
    text: "Estágios",
    url: "/estagios",
    object: "object_18",
    scene: "scene_111",
    view: "view_152",
    withLink: true,
    profilesWithAccess: ["profile_27"],
  },
  {
    icon: <BusinessIcon />,
    text: "Empresas",
    url: "/empresas",
    object: "object_13",
    scene: "scene_104",
    view: "view_142",
    withLink: true,
    profilesWithAccess: ["profile_27"],
  },
  {
    icon: <RecordVoiceOverIcon />,
    text: "Formadores",
    url: "/formadores",
    object: "object_5",
    scene: "scene_47",
    view: "view_58",
    withLink: true,
    profilesWithAccess: ["profile_27"],
  },
];

export const KnackAndFrontEndRelConfigMenu = {
  management: [
    {
      text: "Instalações",
      url: "/instalacoes",
      object: "object_8",
      scene: "scene_34",
      view: "view_41",
      withLink: true,
    },
    {
      text: "Centros",
      url: "/centros",
      scene: "scene_130",
      view: "view_181",
      object: "object_22",
      withLink: true,
    },
    {
      text: "Orientadores Pedagógicos",
      url: "/orientadores-pedagogicos",
      object: "object_20",
      scene: "scene_115",
      view: "view_174",
      withLink: true,
    },
    {
      text: "Agentes",
      url: "/agentes",
      object: "object_21",
      scene: "scene_135",
      view: "view_187",
      withLink: true,
    },
    {
      text: "Pessoas",
      url: "/pessoas",
      scene: "scene_226",
      view: "view_313",
      object: "object_14",
      withLink: true,
    },
    {
      text: "Cursos",
      url: "/cursos",
      object: "object_9",
      scene: "scene_38",
      view: "view_46",
      withLink: true,
    },
    {
      text: "Módulos",
      url: "/modulos",
      object: "object_36",
      scene: "scene_233",
      view: "view_321",
      withLink: true,
    },
    {
      text: "Sessões",
      url: "/sessoes",
      object: "object_37",
      scene: "scene_238",
      view: "view_328",
      withLink: true,
    },
    {
      text: "Colaboradores",
      url: "/colaboradores",
      object: "object_24",
      scene: "scene_196",
      view: "view_273",
      withLink: true,
    },
    {
      text: "Gerentes",
      url: "/gerentes",
      object: "object_3",
      scene: "scene_345",
      view: "view_454",
      withLink: false,
      hideMenuForAll: true,
    },
    {
      text: "Documentos",
      url: "/documentos",
      object: "object_47",
      scene: "scene_348",
      view: "view_457",
      withLink: true,
    },
  ],
  configurations: [
    // {
    //     text: 'Profissões',
    //     url: '/profissoes',
    //     object: 'object_12',
    //     withLink: false
    // },
    {
      text: "Áreas de formação",
      url: "/areas-formacao",
      object: "object_10",
      scene: "scene_171",
      view: "view_231",
      withLink: false,
    },
    {
      text: "Funções Colaborador",
      url: "/funcoes-colaborador",
      object: "object_25",
      scene: "scene_180",
      view: "view_243",
      withLink: false,
    },
    {
      text: "Funções Pessoas",
      url: "/funcoes-pessoa",
      object: "object_15",
      scene: "scene_176",
      view: "view_238",
      withLink: false,
    },
    {
      text: "Grupos Parceiros",
      url: "/grupos-parceiros",
      object: "object_29",
      scene: "scene_201",
      view: "view_279",
      withLink: true,
    },
    {
      text: "Tipos de Parceria",
      url: "/tipos-parceria",
      object: "object_30",
      scene: "scene_205",
      view: "view_284",
      withLink: false,
    },
    {
      text: "Habilitações Literárias",
      url: "/habilitacoes-literarias",
      object: "object_11",
      scene: "scene_288",
      view: "view_388",
      withLink: false,
    },
    {
      text: "Templates",
      url: "/templates",
      object: "object_44",
      scene: "scene_302",
      view: "view_406",
      withLink: false,
    },
    {
      text: "Distritos",
      url: "/districtos",
      object: "object_43",
      scene: "scene_333",
      view: "view_442",
      withLink: false,
    },
    {
      text: "Configuração de campos",
      url: "/config-fields#configurar-campos/",
      object: "not_defined",
    },
    // {
    //     text: 'utilizadores',
    //     url: '/utilizadores',
    //     object: 'object_03',
    //     withLink: false
    // }
  ],
};

export const knackRoles = [
  {
    name: "Aluno",
    path: "alunos",
    knackRole: "profile_4",
    knackObject: "object_4",
    userPictureField: "field_20",
    scene: "scene_29",
    view: "view_31",
  },
  {
    name: "Formador",
    path: "formadores",
    knackRole: "profile_5",
    knackObject: "object_5",
    scene: "scene_48",
    view: "view_59",
    userPictureField: "field_36",
  },
  {
    name: "Orientador Pedagógico",
    path: "orientadores-pedagogicos",
    knackRole: "profile_20",
    knackObject: "object_20",
    userPictureField: "field_211",
    scene: "scene_127",
    view: "view_177",
  },
  {
    name: "Agente",
    path: "agentes",
    knackRole: "profile_21",
    knackObject: "object_21",
    userPictureField: "field_226",
    scene: "scene_190",
    view: "view_251",
  },
  {
    name: "Colaborador Sede",
    path: "colaboradores",
    knackRole: "profile_24",
    knackObject: "object_24",
    userPictureField: "field_252",
    scene: "scene_198",
    view: "view_274",
  },
  {
    name: "Admin",
    path: "admins",
    knackRole: "profile_27",
    knackObject: "object_27",
    userPictureField: "field_485",
    scene: "scene_287",
    view: "view_386",
    main: true,
  },
];
