import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, useMediaQuery } from "@material-ui/core";
import DeleteButton from "../../layout/DeleteButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDetailHeaderConfig } from "../../../redux/actions/knackconfig";
import { fetchHeaderFields } from "../../../redux/actions/api/dataApiCalls";
import { renderFieldValue } from "../../../utils/renderFieldValue";
import { checkForErrors } from "../../../utils/errors";
import { clearErrors } from "../../../redux/actions/errors";
import _ from "lodash";
import { userHasPermissionsToEdit } from "../../../utils/userPermissions";
import TalentoButton from "../TalentoButton";

const DetailHeader = (props) => {
  const {
    objectLabelPlural,
    objectLabelSingular,
    id,
    icon,
    detailHeaderFields,
    object,
    detailHeaderConfig,
    auth,
    scene,
    view,
    errors,
    deletable,
  } = props;
  const { profile_keys, userId } = props.auth;
  const { /**deleleteRolesPermissions,*/ editRolesPermissions } =
    props.userPermissions;
  const [isLoaded, setIsLoaded] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useMemo(async () => {
    // props.clearErrors();
    await props.fetchDetailHeaderConfig(object);
    await props.fetchHeaderFields(id, scene, view, auth);
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, id, scene, view]);

  useEffect(() => {
    props.clearErrors();

    const allowedUserIds =
      process.env.REACT_APP_ALLOWED_ACCOUNT_IDS_DELETE.split(", ");

    setDisableDeleteButton(!allowedUserIds.includes(userId));
    setDisableEditButton(
      userHasPermissionsToEdit(editRolesPermissions, profile_keys)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const finalDetailHeaderConfig = detailHeaderConfig?.filter(
    (line) =>
      !!line?.fields?.filter(
        (el) => _.pick(detailHeaderFields, el?.key)[el?.key]
      )?.length
  );

  const getMargin = (lineIndex) => {
    if (lineIndex === 0 || lineIndex === finalDetailHeaderConfig?.length)
      return 0;
    return 10;
  };

  if (!detailHeaderConfig) return null;

  return (
    <Paper variant="outlined" className={classes.backGroundPaper}>
      <Grid container spacing={2} className={classes.mainContainer}>
        <Grid>{icon}</Grid>
        {checkForErrors({ errors, record: detailHeaderFields, isLoaded }) || (
          <Grid item={true}>
            {finalDetailHeaderConfig.map((line, lineIndex) => (
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: getMargin(lineIndex),
                }}
                key={lineIndex}
              >
                {line.fields.map((field, index) => {
                  const isFirst = lineIndex === 0 && index === 0;
                  const displayElement = renderFieldValue({
                    field,
                    record: detailHeaderFields,
                    layout: "header",
                  });
                  if (!displayElement) return null;
                  return (
                    <Typography
                      key={field.key}
                      color={isFirst ? "inherit" : "textSecondary"}
                      variant={isFirst ? "h6" : "inherit"}
                      className={
                        isFirst ? classes.fieldOne : classes.otherFields
                      }
                    >
                      {displayElement}
                    </Typography>
                  );
                })}
              </Grid>
            ))}
          </Grid>
        )}

        <Grid
          item
          style={{
            display: "flex",
            marginLeft: !mobile ? "auto" : "",
            alignItems: "center",
          }}
        >
          <Grid item>
            {disableEditButton ? null : (
              <TalentoButton
                component={Link}
                to={`/${objectLabelPlural.toLowerCase()}/editar-${objectLabelSingular.toLowerCase()}/${id}`}
                variant="contained"
                color="secondary"
                className={classes.editButton}
              >
                Editar {objectLabelSingular}
              </TalentoButton>
            )}
          </Grid>
          <Grid item>
            {!disableDeleteButton && deletable && (
              <DeleteButton
                recordId={id}
                objectLabelPlural={objectLabelPlural}
                objectLabelSingular={objectLabelSingular}
                scene={scene}
                view={view}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  backGroundPaper: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: 4,
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  fieldOne: {
    marginTop: 5,
  },
  otherFields: {
    paddingRight: theme.spacing(2),
    fontSize: 16,
    minWidth: 80,
  },
  editButton: {
    marginLeft: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}));

const mapStateToProps = (state) => ({
  detailHeaderConfig: state.detailHeaderConfig,
  detailHeaderFields: state.detailHeaderFields,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  fetchDetailHeaderConfig,
  fetchHeaderFields,
  clearErrors,
})(DetailHeader);
