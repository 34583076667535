export const sendMassEmailWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/opdt8qmgio2uzlvw4xjkj3xwz94mnp3q"
    : "https://hook.eu2.make.com/46pbkph441uwq5c6k6tpa9qehq5d5chu";
export const sendMassSmsWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/opdt8qmgio2uzlvw4xjkj3xwz94mnp3q"
    : "https://hook.eu2.make.com/46pbkph441uwq5c6k6tpa9qehq5d5chu";
export const createDiplomaWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/s913exaccy7rds46n1tgl4itmhonwf7v"
    : "https://hook.eu2.make.com/a63txipuzcaf34xicm9at0xokbkxe54r";
export const createContractFlowWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/28i7bytcr5c4yubecnfwalxx3s74f14f"
    : "https://hook.eu2.make.com/kivmxnv67cfkc5q1xsjoj7h2m952t1kc";
export const createStudentPresencesWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/qpmqmwcm6uvkmg6t2ujjc11boe03fng5"
    : "https://hook.eu2.make.com/n7nlgsd3l5cdb3cup33xd4v4xxmpkp9y";
export const sendContractToSignWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/4mpwntp7nrxc1551qfiap761n667k3mp"
    : "https://hook.eu2.make.com/xewn6bnahirs32y2rwncq41vfunje9jf";
export const associateCareerManagersWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/w6rv5pnjugasy66maw427lq2jjwh4j2n"
    : "https://hook.eu2.make.com/61smw327ju9x2i3e33alg1bxvzb54waf";
export const associateCareerManagerProcuraAtivaWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/qnmfmc4ygazfm24qk16jgamqjo5rcrg2"
    : "https://hook.eu2.make.com/89l9cbpoqfciyshf6ysifliv4oskun7p";
export const changeStudentsContractStatusWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/vnj1y2sepi7qp0js0o9e37gud2681eli"
    : "https://hook.eu2.make.com/a31ol4x9doip6bkndh6ir8vp2pvu2ygt";
export const createPresencesInternShipsEvaluationWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/mljtlh885x3lhspoejrnlwq7kvi840ty"
    : "https://hook.eu2.make.com/gu4vuaek3558ugsdop1erifo4g9bl2og";
export const updateTeachersPermissionsWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/iimbmb39a44ihpdslned8pq6jy6shpas"
    : "https://hook.eu2.make.com/d0vcbgxh6huknkr7qh5ue7e4fac49mw5";
export const createSessionClassesWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/1yrqz26xk2n0d5qyb8bs2jlef8f61cby"
    : "https://hook.eu2.make.com/frz3g6k8vfw2nvs7d5ur7ydldigglufe";
export const createPresencesManuallyWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/huz1ipmpv338igbj25adn84jrtk436lp"
    : "https://hook.eu2.make.com/duvsf4ggce5yackjeo9uyf59jrbhw0go";
export const sendMassEmailAccessPuzzleWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/e2xcvlbsa9lhbjirhs33k4lu57iw8tnz"
    : "https://hook.eu2.make.com/xkfotpo2iegcd8q562k3qoc95ws3rdqu";
export const submitEvaluationsWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/snhhdodaxbd474vd463747mmcyg9laev"
    : "https://hook.eu2.make.com/7qo75cvmphjoigs8lcu51c9uclg1vvsx";
export const getClassesForStudentsWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/87vtsm4cio4vbqhv8shhq71gmuojsr8g"
    : "https://hook.eu2.make.com/2ipyxybg8h7octuadml06eabah6swm8w";
export const sendCertificaAcoresWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/88acyy4co2u44y2fts9wwuvmjst7jwx8"
    : "https://hook.eu2.make.com/w712ysm0hpu4qxjv2allsq5qk1bagwy1";
export const pushClassesOneWeekForwardWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/5b9nh66492rikpcjoof6px2n7eiom1ek"
    : "";
export const sendEmailWithAccessAndClassInformationWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/chray9rexxay60uiw1b8taht7dawaasn"
    : "";
export const sendCertificateSigoWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/fkh452nide06kkud2e7vaarzddko8b6s"
    : "https://hook.eu2.make.com/wbd6h1anou79wm5z345n6ra4o8gmd5x5";
export const exportAndDeletePresencesWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/v99r9eyrnrq1hqwl4fu52ycx56ncxxtx"
    : "https://hook.eu2.make.com/nx2repvw11u31iwycv4nqsmn0g4jr70x";
export const generateDebitFileWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/oos4owre5nm32mvacihonmnuvi5lv7xl"
    : "https://hook.eu2.make.com/7i5xlm3snfdtdzrwejt5m52h1ww2re8w";
export const validateCrmIdWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/c7iht1hrt102dzhmrc5ywsvixg78esca"
    : "https://hook.eu2.make.com/c7iht1hrt102dzhmrc5ywsvixg78esca";
export const downloadQualificaDocumentsWebhook =
  process.env.REACT_APP_APP_ENV === "production"
    ? "https://hook.eu2.make.com/f0tq40lyiyb7irgb6eii4y3v0j27dfs9"
    : "https://hook.eu2.make.com/e75ijirukgbti4olrp5mnkwvpt4ltrd6";
