import React from "react";
import DetailView from "../layout/Detail/DetailView";
import AddForm from "../layout/forms/AddForm";
import EditForm from "../layout/forms/EditForm";
import ListView from "../layout/ListView";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import {
  createCertificateLetter,
  createDiploma,
} from "../../redux/actions/documents/class";
import { connect } from "react-redux";
import {
  Contrato,
  FormacaoModularTurma,
  Profiles,
  Turma,
} from "../../utils/knackKeys";

// SHARED CONSTANTS
const object = "object_7";
const objectLabel = "Turma";
const objectLabelSingular = "Turma";
const objectLabelPlural = "Turmas";
const listUrl = "/turmas";
const identifierNameField = "field_68";
const icon = (
  <CastForEducationIcon
    fontSize="large"
    style={{ marginRight: 16, color: "#148FF6" }}
  />
);
const userPermissionsField = "field_431";

let DetailClass = (props) => {
  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      icon={icon}
      id={props.match.params.id}
      scene={"scene_44"}
      view={"view_54"}
      sceneEdit={"scene_46"}
      viewEdit={"view_57"}
      deletable={true}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: [
          "profile_5",
          "profile_4",
          "profile_20",
          "profile_21",
        ],
        deleleteRolesPermissions: [],
        editRolesPermissions: ["profile_21"],
        documentsRolesPermissions: [],
      }}
      tabs={[
        {
          title: "Formação Modular",
          position: 1,
          conditionalDisplay: {
            isActive: true,
            object: Turma.object,
            field: Turma.fields.FORMACAO_MODULAR,
            value: "Sim",
          },
          params: {
            object: FormacaoModularTurma.object,
            objectLabelPlural: "Formacao Modular",
            objectLabelSingular: "Formacao Modular",
            slug: "formacao-modular",
            parentListUrl: listUrl,
            isDetail: true,
            scene: FormacaoModularTurma.details.scene,
            view: FormacaoModularTurma.details.view,
            sceneEdit: FormacaoModularTurma.edit.scene,
            viewEdit: FormacaoModularTurma.edit.view,
            pinned: ["Abrir"],
            userPermissions: {
              userPermissionsField: "field_455",
              viewRolesPermissions: [Profiles.ORIENTADOR_PEDAGOGICO],
              tabRolesPermissions: [Profiles.ORIENTADOR_PEDAGOGICO],
              clickTableRolesPermissions: [],
              editRolesPermissions: [],
              ignoreUserPermissionsField: true,
            },
            extraConfigs: {
              showCreateButton: "no",
              showUpdateButton: true,
            },
            filterByConnectedField: {
              connected_field: FormacaoModularTurma.fields.TURMA,
              id: props.match.params.id,
              identifierNameField,
              parentsConnectedField: Turma.fields.FORMACAO_MODULAR_TURMA,
            },
          },
        },
        {
          title: "Alunos",
          position: 2,
          params: {
            object: "object_6",
            objectLabelPlural: "Contratos",
            objectLabelSingular: "Contrato",
            slug: "alunos",
            scene: Contrato.list.scene,
            view: Contrato.list.view,
            pinned: [
              "Número do contrato",
              "Aluno",
              "Abrir",
              "Estado do contrato",
            ],
            userPermissions: {
              userPermissionsField: "field_432",
              viewRolesPermissions: ["profile_5", "profile_21"],
              tabRolesPermissions: ["profile_5", "profile_21", "profile_20"],
              clickTableRolesPermissions: ["profile_21", "profile_20"],
            },
            extraConfigs: {
              showCreateButton: "no",
              actionsMenu: [
                {
                  name: "Enviar Certifica Açores",
                  customActionId: "sendCertificaAcores",
                },
                {
                  name: "Criar presenças + estágio + avaliação",
                  customActionId: "createPresencesInternShipsEvaluation",
                },
                {
                  name: "Alterar estado do contrato",
                  customActionId: "changeStudentsContractStatus",
                },
                {
                  name: "Descarregar Documentos Qualifica",
                  customActionId: "downloadQualificaDocuments",
                },
              ],
            },
            filterByConnectedField: {
              connected_field: "field_313",
              id: props.match.params.id,
            },
          },
        },
        {
          title: "Estágios",
          position: 3,
          conditionalDisplay: {
            isActive: true,
            object: Turma.object,
            field: Turma.fields.FORMACAO_MODULAR,
            value: "Não",
          },
          params: {
            object: "object_18",
            objectLabelPlural: "Estagios",
            objectLabelSingular: "Estagio",
            slug: "estagios",
            scene: "scene_111",
            view: "view_152",
            pinned: ["Nome do Aluno", "Abrir"],
            userPermissions: {
              userPermissionsField: userPermissionsField,
              viewRolesPermissions: [],
            },
            extraConfigs: {
              showCreateButton: "no",
              actionsMenu: [
                {
                  name: "Associar Gestor de Carreira",
                  customActionId: "associateCareerManagers",
                },
              ],
            },
            filterByConnectedField: {
              connected_field: "field_498",
              id: props.match.params.id,
            },
          },
        },
        {
          title: "Aulas",
          position: 4,
          conditionalDisplay: {
            isActive: true,
            object: Turma.object,
            field: Turma.fields.FORMACAO_MODULAR,
            value: "Não",
          },
          params: {
            object: "object_38",
            objectLabelPlural: "Aulas",
            objectLabelSingular: "Aula",
            slug: "aulas",
            scene: "scene_243",
            view: "view_334",
            sort: {
              field: "field_408",
              order: "asc",
            },
            pinned: ["Sessão Nº", "Sessões", "Abrir"],
            userPermissions: {
              userPermissionsField: "field_435",
              viewRolesPermissions: ["profile_5"],
              tabRolesPermissions: ["profile_5"],
              clickTableRolesPermissions: ["profile_5"],
            },
            extraConfigs: {
              showCreateButton: "yes",
              showCreateButtonToProfiles: [
                Profiles.ADMIN,
                Profiles.COLABORADOR_SEDE,
              ],
              actionsMenu: [
                {
                  name: "Criar Aulas",
                  customActionId: "createSessions",
                },
                {
                  name: "Empurrar aulas uma semana",
                  customActionId: "pushClassesOneWeekForward",
                  actionRolesPermissions: ["profile_5"],
                },
                {
                  name: "Atualizar permissões dos formadores",
                  customActionId: "updateTeachersPermissions",
                },
                {
                  name: "Criar presenças",
                  customActionId: "createPresencesManually",
                },
              ],
            },
            filterByConnectedField: {
              connected_field: "field_407",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
            },
          },
        },
        {
          title: "Presenças",
          position: 5,
          conditionalDisplay: {
            isActive: true,
            object: Turma.object,
            field: Turma.fields.FORMACAO_MODULAR,
            value: "Não",
          },
          params: {
            object: "object_39",
            objectLabelPlural: "Presencas",
            objectLabelSingular: "Presenca",
            slug: "presencas",
            scene: "scene_248",
            view: "view_341",
            userPermissions: {
              userPermissionsField: "field_436",
              viewRolesPermissions: [],
              tabRolesPermissions: [],
            },
            extraConfigs: {
              showCreateButton: "no",
              hasDetail: "no",
              actionsMenu: [
                {
                  name: "Criar Presenças",
                  customActionId: "createStudentPresences",
                },
                {
                  name: "Exportar e Apagar Presenças",
                  customActionId: "exportAndDeletePresences",
                },
              ],
            },
            filterByConnectedField: {
              connected_field: "field_429",
              id: props.match.params.id,
            },
          },
        },
        {
          title: "Ava. Final",
          position: 6,
          params: {
            object: "object_41",
            objectLabelPlural: "Avaliacoes",
            objectLabelSingular: "Avaliacao",
            slug: "avaliacao",
            scene: "scene_277",
            view: "view_376",
            sceneEdit: "scene_279",
            viewEdit: "view_377",
            showListPopUpEdit: "yes",
            userPermissions: {
              userPermissionsField: "field_455",
              viewRolesPermissions: ["profile_5"],
              tabRolesPermissions: ["profile_5"],
              clickTableRolesPermissions: ["profile_5"],
            },
            extraConfigs: {
              showCreateButton: "yes",
              showCreateButtonToProfiles: [
                Profiles.ADMIN,
                Profiles.COLABORADOR_SEDE,
              ],
              hasDetail: "no",
              openDifferentDetail: {
                customUrl: "contratos",
                connectedField: "field_500",
              },
              helperText: (
                <span>
                  Quando terminar de submeter todas as avaliações, não se
                  esqueça de correr a Ação{" "}
                  <u>
                    <strong>Avaliações submetidas</strong>
                  </u>
                </span>
              ),
              actionsMenu: [
                {
                  name: "Criar e enviar diplomas",
                  customActionId: "createDiplomas",
                },
                {
                  name: "Avaliações submetidas",
                  customActionId: "submitEvaluations",
                  actionRolesPermissions: [
                    Profiles.FORMADOR,
                    Profiles.COLABORADOR_SEDE,
                  ],
                },
                {
                  name: "Enviar Certificado SIGO",
                  customActionId: "sendCertificateSigo",
                },
              ],
            },
            filterByConnectedField: {
              connected_field: "field_446",
              id: props.match.params.id,
            },
          },
        },
        {
          title: "Materiais Didáticos",
          position: 7,
          conditionalDisplay: {
            isActive: true,
            object: Turma.object,
            field: Turma.fields.FORMACAO_MODULAR,
            value: "Não",
          },
          params: {
            object: "object_46",
            objectLabelPlural: "Materiais Didáticos",
            objectLabelSingular: "Material Didático",
            slug: "material-didatico",
            scene: "scene_329",
            view: "view_437",
            sceneEdit: "scene_331",
            viewEdit: "view_438",
            showListPopUpEdit: "yes",
            userPermissions: {
              userPermissionsField: "",
              viewRolesPermissions: ["profile_5", "profile_4"],
              tabRolesPermissions: ["profile_5", "profile_4"],
              clickTableRolesPermissions: ["profile_5", "profile_4"],
            },
            extraConfigs: {
              showCreateButton: "yes",
              showUploadButtonOnForm: true,
              hasDetail: "no",
            },
            filterByConnectedField: {
              connected_field: "field_575",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
            },
          },
        },
        {
          title: "Ficheiros Turma",
          position: 8,
          params: {
            object: "object_42",
            objectLabelPlural: "Ficheiros Turma",
            objectLabelSingular: "Ficheiro Turma",
            slug: "ficheiros-turma",
            scene: "scene_296",
            view: "view_398",
            sceneEdit: "scene_298",
            viewEdit: "view_399",
            showListPopUpEdit: "yes",
            userPermissions: {
              userPermissionsField: "field_455",
              viewRolesPermissions: [
                "profile_5",
                Profiles.ORIENTADOR_PEDAGOGICO,
                Profiles.AGENTE,
              ],
              tabRolesPermissions: [
                "profile_5",
                Profiles.ORIENTADOR_PEDAGOGICO,
                Profiles.AGENTE,
              ],
              clickTableRolesPermissions: ["profile_5"],
            },
            extraConfigs: {
              showCreateButton: "yes",
              showCreateButtonToProfiles: [
                Profiles.FORMADOR,
                Profiles.ADMIN,
                Profiles.COLABORADOR_SEDE,
              ],
              showUploadButtonOnForm: true,
              hasDetail: "no",
            },
            filterByConnectedField: {
              connected_field: "field_496",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
            },
          },
        },
        {
          title: "Acomp. Pedagógico",
          position: 9,
          params: {
            object: "object_50",
            objectLabelPlural: "Acompanhamento Pedagogico",
            objectLabelSingular: "Acompanhamento Pedagogico",
            slug: "acompanhamento-pedagogico",
            parentListUrl: listUrl,
            isDetail: true,
            scene:
              process.env.NODE_ENV === "development"
                ? "scene_372"
                : "scene_373",
            view:
              process.env.NODE_ENV === "development" ? "view_480" : "view_483",
            sceneEdit:
              process.env.NODE_ENV === "development"
                ? "scene_371"
                : "scene_372",
            viewEdit:
              process.env.NODE_ENV === "development" ? "view_479" : "view_482",
            pinned: ["Abrir"],
            userPermissions: {
              userPermissionsField: "",
              viewRolesPermissions: [],
              tabRolesPermissions: [],
              clickTableRolesPermissions: [],
              editRolesPermissions: [],
            },
            extraConfigs: {
              showCreateButton: "no",
              showUpdateButton: true,
            },
            filterByConnectedField: {
              connected_field: "field_776",
              id: props.match.params.id,
              identifierNameField: identifierNameField,
              parentsConnectedField: "field_777",
            },
          },
        },
      ]}
      documents={[
        {
          type: "certificate_letter",
          title: "Criar Carta para Certificado",
          fileName: "carta_certificado.pdf",
          documentFunction: props.createCertificateLetter,
        },
      ]}
    />
  );
};

const mapDispatchToProps = {
  createCertificateLetter,
  createDiploma,
};

DetailClass = connect(null, mapDispatchToProps)(DetailClass);

const FormAddClass = (props) => {
  return (
    <AddForm
      objectLabel={objectLabel}
      object={object}
      listUrl={"/turmas"}
      scene={"scene_45"}
      view={"view_56"}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: [],
      }}
    />
  );
};

const FormEditClass = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_46"}
      view={"view_57"}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: ["profile_5"],
      }}
    />
  );
};

const ListClasses = (props) => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={"scene_43"}
      view={"view_53"}
      icon={icon}
      pinned={["Turma", "Abrir"]}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: ["profile_5", "profile_21", "profile_20"],
      }}
      extraConfigs={{
        actionsMenu: [
          {
            name: "Enviar SMS em Massa",
            customActionId: "sendMassSMS",
          },
          {
            name: "Enviar Email em Massa",
            customActionId: "sendMassEmail",
          },
          {
            name: "Associar Gestor de Carreira",
            customActionId: "associateCareerManagers",
          },
          {
            name: "Associar GC Procura Ativa",
            customActionId: "associateCareerManagerProcuraAtiva",
          },
        ],
      }}
    />
  );
};

export { DetailClass, FormAddClass, FormEditClass, ListClasses };
