import React, { useEffect, useState } from "react";
import GridFilter from "../filterComponent/GridFilter";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { GridColumns } from "../GridColumns";
import { renderOpenInNewTab } from "../../../utils/Grid/renderFields";
import UploadFile from "../forms/UploadFile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const GridFilters = ({ gridConfig, object }) => {
  if (!gridConfig?.length) return null;
  return <GridFilter gridConfig={gridConfig} object={object} />;
};

export const EmptyGrid = ({ classes }) => {
  return (
    <Typography className={classes.emptyGridText} variant="h4" align="center">
      Sem registos
    </Typography>
  );
};

const renderUploadFileButton = ({ params, sceneEdit, viewEdit, object }) => {
  const url =
    typeof params?.data?.[params.colDef.field] !== "undefined"
      ? params?.data?.[params.colDef.field]
      : "";

  if (!params?.data?.[params.colDef.field]) return null;
  return (
    <UploadFile
      url={url}
      field={params.colDef.field}
      fieldConfig={params.fieldConfig}
      scene={sceneEdit}
      view={viewEdit}
      object={object}
      recordId={params.data.id}
    />
  );
};

const RenderGrid = ({
  onGridReady,
  openDetail,
  updateDataSource,
  gridConfig,
  object,
  isGridEmpty,
  sceneEdit,
  viewEdit,
  showListPopUpEdit,
  pinned,
  baseUrl,
  openDifferentDetail,
  auth,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return (
    <>
      <GridFilters gridConfig={gridConfig} object={object} />
      {isGridEmpty ? (
        <EmptyGrid classes={classes} />
      ) : (
        <div
          className="ag-theme-material"
          style={{ height: "85%", width: "100%" }}
        >
          <AgGridReact
            reactNext={true}
            onGridReady={onGridReady}
            onCellClicked={openDetail}
            datasource={mounted && updateDataSource()}
            immutableData // to work with redux better
            pagination={true}
            getRowNodeId={(data) => data.id}
            defaultColDef={{
              sortable: true,
              filter: false,
            }}
            rowSelection="multiple"
            enableCellChangeFlash={true}
            frameworkComponents={{
              uploadFileComponent: (params) =>
                renderUploadFileButton({ params, sceneEdit, viewEdit, object }),
              newTabComponent: renderOpenInNewTab,
            }}
            rowModelType={"infinite"}
            cacheBlockSize={30}
            rowBuffer={0}
            paginationPageSize={30}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={30}
            infiniteInitialRowCount={30}
            paginationAutoPageSize={false}
            enableCellTextSelection={true}
            rowClassRules={{
              transfered: (params) => {
                if (params.data) {
                  let isTransfered = false;
                  if (params.data.field_556) {
                    const actualClass = params.data.field_556;
                    actualClass.map((element) => {
                      return element.id !== id ? (isTransfered = true) : false;
                    });
                  }
                  return isTransfered;
                }
              },
            }}
          >
            {GridColumns(
              gridConfig,
              auth.profile_keys,
              baseUrl,
              pinned,
              openDifferentDetail
            )}

            <AgGridColumn
              hide={showListPopUpEdit === "yes" ? false : true}
              minWidth={200}
              flex={1}
              headerName={"Editar"}
              cellRenderer={() => "editar"}
            />
          </AgGridReact>
        </div>
      )}
    </>
  );
};

export default RenderGrid;
